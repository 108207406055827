.policyPrivacy {
  padding: 24px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  * {
    margin: 0;
    padding: 0;
  }
}

.title {
  padding: 0 32px;
  & > h1 {
    color: rgba(0,0,0,.85);
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 18px;
    height: 32px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  & > div {
    font-size: 12px;
    color: rgba(0,0,0,.65);
    height: 17px;
    line-height: 17px;
  }
  padding-bottom: 32px;
}

.update {
  height: 17px;
  font-size: 12px;
  font-family: HelveticaNeue;
  color: rgba(0, 0, 0, 0.65);
  line-height: 14px;
}

.content {
  min-width: 900px;
  width: 70%;
  margin: 0 auto;
  // padding: 0 15%;
  box-sizing: content-box;
  font-family: PingFangSC-Regular, PingFang SC;
  flex: 1;
  overflow: auto;
  ul {
    list-style: none;
  }
  p {
    line-height: 24px;
  }
}

.listItem {
  padding-left: 32px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 12px;
    border-radius: 50%;
    transform: translateY(-50%);
    background: #000;
    height: 6px;
    width: 6px;
    bottom: 50%;
  }
}

.desc {
  padding-top: 24px;
  padding-bottom: 32px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
}

.ruleTitle {
  height: 20px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  margin-bottom: 16px;
}

.section {
  padding-bottom: 32px;
}

.ruleBox {
  font-size: 14px;
  padding-top: 24px;
}

.ruleSectionBox {
  @extend .ruleBox;
  border-top: 1px solid rgba(0,0,0,.08);
}

.subRuleBox {
  margin-top: 24px;
  padding-top: 24px;
}


.wrap {
  overflow: auto;
}
