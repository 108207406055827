// 样式变量申明
// 主要颜色
$primaryColor: var(--primary-color, 'rgb(0, 99, 171)');
// 主要Hover颜色
$primaryHoverColor: var(--link-hover-color, 'rgb(29, 124, 184)');
// 主要Active颜色
$primaryActiveColor: var(--primary-color, 'rgb(0, 99, 171)');
// 默认边框颜色
$defaultBorderColor: #e8e8e8;
// 默认边框样式
$defaultBorder: 1px solid $defaultBorderColor;
$defaultTransition: all 0.15s ease-in-out;
$kuPrefix: 'portal';
