@import '../../../assets/styles/var.scss';

.container {
    :global(.#{$kuPrefix}-form-item-label label) {
        color: rgba(0, 0, 0, 0.65);
    }
    :global(.#{$kuPrefix}-form-item-required) {
        &:before {
            content: "";
        }
    }
    :global(.#{$kuPrefix}-form-explain) {
        font-size: 12px;
        position: initial;
    }
}

.form {
    width: 550px;
    margin: 50px 90px !important;
    position: relative;
    .form-item {
        width: 100%;
        margin: 0 0 20px 0;
        &:global(.#{$kuPrefix}-form-item-with-help) {
            margin-bottom: 4px;
        }
    }
    .btn {
        margin-right: 15px;
    }
}
