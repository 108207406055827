.loading {
  width: 100%;
  margin-top: 100px;

  text-align: center;
}

.layout {
  position: relative;

  display: flex !important;
  flex-direction: row;

  width: 100vw;
  height: 100vh;
  .lang-loading {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    background: rgba($color: #fff, $alpha: 0.3);

    justify-content: center;
    align-items: center;
  }
}

.menu-zone {
  font-size: 14px;
  z-index: 301;

  // width: 264px;
  height: 100%;

  user-select: none;
  transition: all 0.3s ease-in-out;

  // border-right: 1px solid #e8e8e8;
  // background: #fafafa;

  // 菜单收起
  // &.fold {
  //   width: 64px;
  // }
}

.main-zone {
  overflow: hidden;

  height: 100%;
  min-height: 0;

  background: var(--body-background, '#f5f5f5');

  flex: 1;
}
