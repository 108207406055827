@import './var.scss';
// @import "./antd/modal.rewrite.scss";
// @import "./antd/message.rewrite.scss";
// @import "./antd/form.rewrite.scss";

// body {

//     a,
//     img {
//         -webkit-user-drag: none;
//         color: initial;
//     }

//     :global(.ant-input) {
//         border-radius: 2px;
//     }
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     color: #333;
// }

// 整体滚动条
:global(::-webkit-scrollbar) {
  width: 8px;
  height: 8px;
}

// 滑动轨道样式
:global(::-webkit-scrollbar-track) {
  background: transparent;
}

// 滑块样式
:global(::-webkit-scrollbar-thumb) {
  background: #b2b2b2;
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: content-box;
}

// Notification——>「更多消息「通知提醒框
:global(.notice-center-notification-more) {
  div[class$='notification-notice-content'] {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    div[class$='notification-notice-message'] {
      display: none;
    }
  }
  a[class$='notification-notice-close'] {
    display: none;
  }
}

// 消息中心微信扫码提示popover，不要阴影
:global(.notice-center-popover-wechat-tips) {
  div[class$='popover-inner'] {
    box-shadow: none;
  }
}
