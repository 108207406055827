@import '../../assets/styles/var.scss';

.container {
  position: relative;

  display: flex;
  overflow-y: auto;

  width: 100vw;
  height: 100vh;

  user-select: none;

  background-image: url('./images/bg.jpg');
  background-size: 100%;
  background-size: cover;

  justify-content: center;
  align-items: center;

  .loading {
    :global(.#{$kuPrefix}-spin-dot i) {
      background: #fff;
    }
  }
}

.logo-box {
  height: 48px;
  margin: 20px 0;

  text-align: center;
}

.form-box {
  width: 440px;
  padding: 80px 55px 50px 55px;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0 16px 29px 0 rgba(0, 0, 0, .15);

  &.work-wechat-mode {
    padding: 20px;

    & > .login-form {
      display: none;
    }
  }
}

.form-logo {
  display: flex;

  margin-bottom: 66px;

  justify-content: center;
}

.form-item {
  margin-bottom: 24px;

  :global(.#{$kuPrefix}-form-item-required) {
    &:before {
      margin-right: 0;

      content: '';
    }
  }

  :global(.#{$kuPrefix}-form-item-label label) {
    color: #666;
  }

  :global(.#{$kuPrefix}-form-item-label label:after) {
    content: '';
  }

  &:global(.#{$kuPrefix}-form-item-with-help) {
    margin-bottom: 4px;
  }

  :global(.#{$kuPrefix}-form-explain) {
    font-size: 12px;

    margin-top: 2px;
  }
}
.code-input {
  width: 204px;
}
.code-btm {
  font-size: 14px;

  float: right;

  width: 118px;
  padding: 0;

  text-align: center;
}
.btn-login {
  width: 100%;
  margin-top: 44px;
}

.sso-box {
  display: flex;
  flex-direction: row;

  margin-top: 15px;

  justify-content: space-between;
}

.sso-popover {
  overflow: auto;

  min-width: 100px;
  max-height: 200px;

  user-select: none;
  text-align: center;
}

.sso {
  margin: 10px 0;

  cursor: pointer;
  text-align: left;

  color: $primaryColor;

  &.active {
    color: $primaryActiveColor;
  }
}

.current-sso {
  cursor: pointer;

  color: $primaryColor;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 30px;
  left: 0;

  text-align: center;

  color: #fff;
}

.theme-color {
  color: $primaryColor;
}
.language-box {
  display: flex;
  flex-direction: row;

  margin-top: 15px;

  justify-content: center;
}
.language-select {
  width: auto;
  min-width: 60px;

  border: 0;
  :global(.#{$kuPrefix}-select-selection) {
    border: 0;
  }
  :global(.#{$kuPrefix}-select-selection:focus) {
    box-shadow: none;
  }
}

.qr-wrap {
  flex: 1;

  & > div {
    margin-left: 20px;
  }
}

.work-wechat-wrap {
  display: inline-block;

  cursor: pointer;

  .work-wechat-icon {
    font-size: 20px;
  }
}

.hidden {
  display: none;
}

.dingtalk-wrap {
  display: inline-block;

  cursor: pointer;

  .dingtalk-icon {
    font-size: 20px;
  }
}
