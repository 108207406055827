.container {
    text-align: center;
    margin: 32px;
    color: #333;
    .icon {
        color: #c9c9c9;
        font-size: 100px;
    }
    .h {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0;
        color: #333;
    }
    .desc {
        font-size: 14px;
    }
}
