.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.body {
    text-align: center;
}

.icon {
    font-size: 85px;
    color: #C9C9C9;
}

.title {
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 8px;
}

p {
    margin: 0;
}

.bgImage {
    height: 183px;
}
