@import '../../assets/styles/var.scss';

.container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
}

.header {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    height: 56px;
    line-height: 56px;
    background: #fff;
    padding: 0 20px;
    margin: 0;
}

.main {
    flex: 1 1 auto;
    margin: 20px;
    display: flex;
    background: #fff;
    flex-direction: row;
    overflow: auto;
}

.menu {
    width: 180px !important;
    padding-top: 5px;

    :global(.#{$kuPrefix}-menu-item-selected) {
        background-color: #E5F6F2 !important;
    }
}

.body {
    flex: 1;
    overflow: auto;
}
