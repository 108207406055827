@import '../../../assets/styles/var.scss';

.wechat-wrap {
  position: relative;

  text-align: center;

  & > div {
    padding-top: 40px;
  }
}

.closed-icon {
  font-size: 16px;

  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;

  &:hover {
    color: $primaryHoverColor;
  }
}
