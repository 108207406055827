@import '../../../assets/styles/var.scss';

.form {
    position: relative;
}

.form-title {
    font-size: 16px;
    margin-bottom: 10px;
}

.form-item {
    margin-bottom: 24px;
    &:global(.#{$kuPrefix}-form-item-with-help) {
        margin-bottom: 4px;
    }
    :global(.#{$kuPrefix}-form-explain) {
        font-size: 12px;
        margin-top: 2px;
    }
}

.btn-login {
    width: 100%;
    margin-top: 20px;
}
