@import '../../../assets/styles/var.scss';

.dingtalk-wrap {
  position: relative;

  text-align: center;

  & > div {
    padding-top: 40px;
  }
}

.closed-icon {
  font-size: 16px;

  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;

  &:hover {
    color: $primaryHoverColor;
  }
}

.header {
  position: absolute;
  text-align: center;
  font-size: 24px;
  width: 100%;

  &::after {
    content: '钉钉登录';
    color: $primaryHoverColor;
    display: inline-block;
    vertical-align: middle;
  }
}

.icon {
  font-size: 40px;
}
