.container {
    width: 400px;
    position: relative;
}

.tree-box-container {
    border: 1px solid rgba(232, 232, 232, 1);
}

.tree-box {
    overflow: auto;
    transition: all 0.3s ease-in-out;
}

.expand {
    font-size: 12px;
    height: 28px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;

    .btn-text {

        span,
        svg {
            margin: 0 1px;
            font-size: 12px;
            vertical-align: middle;
        }
    }
}
