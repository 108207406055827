@import "../../../assets/styles/var.scss";
.container {
    padding: 20px;
}

.fieldset {
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    .label {
        width: 200px;
        display: flex;
        justify-content: flex-end;
        text-align: right;
        margin-right: 15px;
        position: relative;
        &:after {
            position: absolute;
            right: -14px;
            content: "：";
        }
    }
    .content {
        flex: 1;
    }
}

.btn-link {
    margin-left: 20px;
    color: $primaryColor;
    &:hover {
        color: $primaryHoverColor;
    }
}

.btn-cancel {
    margin-left: 20px;
    color: #3a3a3a;
    &:hover {
        color: #333;
    }
}

.divider-box {
    display: flex;
    flex-direction: row;
    align-items: center;
}
